import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Figure } from '@apmg/titan';
import { Body } from '@apmg/amat';
import Head from 'next/head';
import ContentHeader from './ContentHeader';
import LinkOverride from '../AmatOverrides/LinkOverride';
import ApmRelatedLinkListItemOverride from '../AmatOverrides/ApmRelatedLinkListItemOverride';
import ApmRelatedLinkOverride from '../AmatOverrides/ApmRelatedLinkOverride';
import ApmRelatedListOverride from '../AmatOverrides/ApmRelatedListOverride';
import ApmImageOverride from '../AmatOverrides/ApmImageOverride';
import { insertDonateNode } from '../../utils/proseMirror';
import {
  getVideoPlayer,
  getVideoEmbedUrl,
  audioDownloadPrefix,
} from '../../utils/utils';
import RelatedLinks from '../RelatedLinks/RelatedLinks';
import HTMLTextbox from '../PublishHtml/HTMLTextbox';
import useViafouraHook from '../../hooks/useViafoura';
import ViafouraLivestory from '../Viafoura/ViafouraLivestory';
import { useRouter } from 'next/router';
import Link from 'next/link';
import AddFavoriteWrapper from 'components/Data/AddFavoriteWrapper';

const Content = ({
  elementClass,
  title,
  subtitle,
  description,
  id,
  dateline,
  authors,
  headingLevel,
  publishDate,
  updatedPublishDate,
  publishedTags,
  shareButtons,
  audio,
  audioPlayButton,
  body,
  embeddedAssets,
  tag,
  image,
  imageCaption,
  imageCredit,
  imageCreditHref,
  minimal,
  redistributable,
  summary,
  video,
  useDonate,
  primaryCollection,
  collections,
  relatedLinksBlacklist,
  useViafoura,
  useLivechat,
  rawHTML,
  transcript,
  donateAsk,
  location,
  venue,
  occurrences,
  resourceType,
  contentUrl,
}) => {
  const { asPath } = useRouter();

  const { viaFouraReady } = useViafouraHook(id);

  const classes = classNames({
    content: true,
    [elementClass]: elementClass,
  });

  let largest;
  if (image && redistributable) {
    const instances = image?.props.image.aspect_ratios.uncropped.instances;
    largest = instances.reduce((accum = { width: 0 }, curr) => {
      return accum.width > curr.width ? accum : curr;
    });
  }

  let ispartOf2024ElectionCollections = false;

  if (collections) {
    ispartOf2024ElectionCollections =
      collections.filter(
        (coll) =>
          coll.canonicalSlug == '2024-voter-guides/2024-voter-guides-english'
      ).length > 0;
  }

  let nodeData = <></>;
  nodeData = JSON.parse(body);

  if (useDonate) {
    nodeData = insertDonateNode(nodeData);
  }
  let isAmp = false;
  let videoPlayer = video && getVideoPlayer(video, isAmp, title);
  const tags =
    publishedTags && publishedTags.length > 0 && publishedTags.join(', ');

  const audioSchema = audio?.encodings[0] && {
    '@context': 'https://schema.org',
    '@type': 'AudioObject',
    name: `Audio: ${audio.title}`,
    description: description,
    url: `https://www.mprnews.org${asPath}`,
    datePublished: publishDate.props.dateTime,
    keywords: tags,
    publisher: {
      '@type': 'NewsMediaOrganization',
      name: 'MPR News',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.mprnews.org/mpr-news-1080x1080.png',
      },
    },
    contentUrl: audioDownloadPrefix(audio.encodings[0].playFilePath),
  };

  const videoSchema = video && {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: `Video: ${title}`,
    description: video.caption ? video.caption : description,
    url: `https://www.mprnews.org${asPath}`,
    datePublished: publishDate.props.dateTime,
    keywords: tags,
    publisher: {
      '@type': 'NewsMediaOrganization',
      name: 'MPR News',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.mprnews.org/mpr-news-1080x1080.png',
      },
    },
    embedUrl: getVideoEmbedUrl(video.url),
  };

  const formatter = new Intl.DateTimeFormat('en-us', {
    timeStyle: 'short',
    dateStyle: 'long',
  });

  const MNVoterGuideRelatedLinks = [
    {
      canonicalSlug: 'voterguides/2024',
      title: 'Voter Guide Home',
    },
    { canonicalSlug: 'build-your-ballot', title: 'Build Your Ballot Home' },
    {
      canonicalSlug: 'minnesota-voter-helpdesk',
      title: 'Your Vote MN Home',
    },
    {
      canonicalSlug: 'politics/election-2024',
      title: 'The latest election news from MPR News',
    },
  ];

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      primaryCollection: primaryCollection?.canonicalSlug,
      contentTopic: primaryCollection?.title,
    });
  });

  return (
    <article className={`${classes}`}>
      {audio?.encodings[0] && (
        <Head>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(audioSchema) }}
          />
        </Head>
      )}
      {video && (
        <Head>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(videoSchema) }}
          />
        </Head>
      )}
      <ContentHeader
        title={title}
        authors={authors}
        headingLevel={headingLevel}
        publishDate={publishDate}
        updatedPublishDate={updatedPublishDate}
        subtitle={subtitle}
        dateline={dateline}
        primaryCollection={primaryCollection}
        tag={tag}
      />

      {image && !video && (
        <div
          className={`story-primary-visual ${
            !imageCaption && `story-primary-no-caption`
          }`}
        >
          <Figure
            caption={imageCaption}
            credit={imageCredit}
            creditHref={imageCreditHref}
            elementClass={'content_figure'}
            image={image}
          />
          {largest && minimal && (
            <div className="content_newsPartners">
              <Link className="link" href={largest.url}>
                Download full resolution image
              </Link>
            </div>
          )}
        </div>
      )}
      {video && (
        <div className="story-primary-visual video">
          <figure className="figure">
            {video && videoPlayer}
            {video.caption && (
              <figcaption className="figure_caption">
                <div className="figure_caption_content">{video.caption}</div>
                {video.credit.name && video.credit.url && (
                  <Link
                    href={video.credit.url}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={`${video.credit.name} - video link opens a new tab`}
                    passHref
                  >
                    <span className="figure_credit">{video.credit.name}</span>
                  </Link>
                )}
                {video.credit.name && !video.credit.url && (
                  <span className="figure_credit">{video.credit.name}</span>
                )}
              </figcaption>
            )}
          </figure>
        </div>
      )}
      <div className="story-audio-save-container">
        {audioPlayButton && (
          <div className="story-audio">{audioPlayButton}</div>
        )}
        <div className="story-audio-save">
          <AddFavoriteWrapper
            title={title}
            contentArea="mprnews"
            resourceType={resourceType}
            id={id}
            slug={contentUrl}
          />
        </div>
        {shareButtons && shareButtons}
      </div>

      <div className="event-wrapper">
        {location && (
          <div className="type-lg event-item">
            <b>Location:</b> <span>{location}</span>
          </div>
        )}
        {venue && (
          <div className="type-lg event-item">
            <b>Venue:</b> <span>{venue}</span>
          </div>
        )}
        {occurrences &&
          occurrences.map((occurrence, idx) => {
            const startdate = new Date(occurrence.startTime);
            const enddate = new Date(occurrence.endTime);

            return (
              <div key={idx} data-testid="event">
                <div className="type-lg event-item">
                  <b>Time:</b>{' '}
                  <span>
                    {formatter.format(startdate)} - {formatter.format(enddate)}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
      <div className="story-wrapper">
        {summary && (
          <div className="story-quick-read">
            <h2 className="story-quick-read-label type-md type-caps">
              Quick <span>Read</span>
            </h2>
            <div className="story-quick-read-content type-sm">{summary}</div>
          </div>
        )}
        <div className="story-body userContent">
          {typeof useViafoura != 'undefined' && (
            <ViafouraLivestory viaFouraReady={viaFouraReady} />
          )}
          {rawHTML && <HTMLTextbox html={rawHTML} />}
          <Body
            nodeData={nodeData}
            embedded={embeddedAssets}
            minimal={minimal}
            isAmp={false}
            mobileAr="square"
            media={['max-width: 512px', 'min-width: 513px']}
            overrides={{
              link: LinkOverride,
              apm_related_link: ApmRelatedLinkOverride,
              apm_related_link_list_item: ApmRelatedLinkListItemOverride,
              apm_related_list: ApmRelatedListOverride,
              apm_image: ApmImageOverride,
              donate_ask: donateAsk,
            }}
          />

          {primaryCollection?.results?.items?.length &&
            !relatedLinksBlacklist?.includes(
              primaryCollection?.canonicalSlug
            ) && <RelatedLinks items={primaryCollection.results.items} />}
          {transcript}
          {useLivechat && viaFouraReady && (
            <div
              className="viafoura"
              id="livechat-wrapper"
              style={{ height: '550px' }}
            >
              <vf-livechat></vf-livechat>
              <vf-tray-trigger floating="true"></vf-tray-trigger>
            </div>
          )}
          {ispartOf2024ElectionCollections && (
            <RelatedLinks items={MNVoterGuideRelatedLinks} />
          )}
        </div>
      </div>
    </article>
  );
};

Content.propTypes = {
  elementClass: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  summary: PropTypes.string,
  id: PropTypes.string,
  dateline: PropTypes.string,
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
    })
  ),
  headingLevel: PropTypes.number,
  publishDate: PropTypes.node,
  updatedPublishDate: PropTypes.node,
  publishedTags: PropTypes.array,
  audio: PropTypes.object,
  audioPlayButton: PropTypes.node,
  shareButtons: PropTypes.node,
  body: PropTypes.string,
  embeddedAssets: PropTypes.object,
  tag: PropTypes.shape({
    tagName: PropTypes.string,
    to: PropTypes.string,
  }),
  image: PropTypes.node,
  imageCaption: PropTypes.string,
  imageCredit: PropTypes.string,
  imageCreditHref: PropTypes.string,
  minimal: PropTypes.bool,
  redistributable: PropTypes.bool,
  video: PropTypes.object,
  useDonate: PropTypes.bool,
  primaryCollection: PropTypes.shape({
    canonicalSlug: PropTypes.string,
    title: PropTypes.string,
    resourceType: PropTypes.string,
    results: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          resourceType: PropTypes.string,
          canonicalSlug: PropTypes.string,
        })
      ),
    }),
  }),
  collections: PropTypes.array,
  relatedLinksBlacklist: PropTypes.arrayOf(PropTypes.string),
  useViafoura: PropTypes.shape({
    slug: PropTypes.string,
  }),
  useLivechat: PropTypes.bool,
  rawHTML: PropTypes.string,
  transcript: PropTypes.node,
  donateAsk: PropTypes.func,
  location: PropTypes.string,
  venue: PropTypes.string,
  resourceType: PropTypes.string,
  contentUrl: PropTypes.string,
  occurrences: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.string,
      endTime: PropTypes.string,
    })
  ),
};

export default Content;
